import { mergeCn } from "../utils";
import { InnerSection, OuterSection } from "./SectionContainer";

export const FooterSection = () => (
  <OuterSection className="bg-mainGreen text-white">
    <InnerSection className="flex flex-col gap-4 items-center w-full">
      <div className="flex flex-col gap-4 w-full">
        <h1 className="font-black text-2xl sm:w-[22rem] md:w-[24rem]">
          Beverage Container Return Scheme
        </h1>
        <div
          className={mergeCn(
            "leading-tight flex flex-col gap-3",
            "text-sm md:text-base md:leading-[1.35rem] sm:w-3/4 md:w-2/3"
          )}
        >
          <p>
            Best viewed using the current and previous release of Chrome and
            Safari
          </p>
        </div>
      </div>
      <div className="w-full border-b" />
      <div className="flex gap-8 text-xs w-full justify-between">
        <div>© BCRS Ltd 2024</div>
        <div className="flex gap-5">
          <a href="/">About</a>
          <a href="/contact">Contact us</a>
        </div>
      </div>
    </InnerSection>
  </OuterSection>
);
